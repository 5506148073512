<template>
  <div class="container py-5 py-lg-8">
    <div v-for="customer in customers" :key="customer.type" class="mb-lg-8">
      <h2 class="content-title fs-1 text-center mb-5 mb-lg-8">{{ customer.name }}</h2>
      <div class="px-lg-6">
        <div class="row pt-4">
          <div
            class="col-6 col-md-4 col-lg-3 mb-3"
            v-for="item in customer.content"
            :key="`${item.type}${item.id}`"
          >
            <img :src="$imageFilters(item.url)" :alt="item.id" class="border" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumb: [
        {
          name: "關於仁大",
          path: "/company",
        },
        {
          name: "客戶群",
          path: "/customer",
        },
      ],
      customers: [
        {
          type: "telecom",
          name: "電信與製造",
          content: [
            { id: 1, url: "/images/customer/tele-logo-1.png" },
            { id: 2, url: "/images/customer/tele-logo-2.png" },
            { id: 3, url: "/images/customer/tele-logo-3.png" },
            { id: 4, url: "/images/customer/tele-logo-4.png" },
            { id: 5, url: "/images/customer/tele-logo-5.png" },
            { id: 6, url: "/images/customer/tele-logo-6.png" },
            { id: 7, url: "/images/customer/tele-logo-7.png" },
            { id: 8, url: "/images/customer/tele-logo-8.png" },
            { id: 9, url: "/images/customer/tele-logo-9.png" },
            { id: 10, url: "/images/customer/tele-logo-10.png" },
            { id: 11, url: "/images/customer/tele-logo-11.png" },
            { id: 12, url: "/images/customer/tele-logo-12.png" },
            { id: 13, url: "/images/customer/tele-logo-13.png" },
            { id: 14, url: "/images/customer/tele-logo-14.png" },
            { id: 15, url: "/images/customer/tele-logo-15.png" },
            { id: 16, url: "/images/customer/tele-logo-16.png" },
          ],
        },
        {
          type: "finance",
          name: "金融",
          content: [
            { id: 1, url: "/images/customer/finance-logo-1.png" },
            { id: 2, url: "/images/customer/finance-logo-2.png" },
            { id: 3, url: "/images/customer/finance-logo-3.png" },
            { id: 4, url: "/images/customer/finance-logo-4.png" },
            { id: 5, url: "/images/customer/finance-logo-5.png" },
            { id: 6, url: "/images/customer/finance-logo-6.png" },
            { id: 7, url: "/images/customer/finance-logo-7.png" },
            { id: 8, url: "/images/customer/finance-logo-8.png" },
            { id: 9, url: "/images/customer/finance-logo-9.png" },
            { id: 10, url: "/images/customer/finance-logo-10.png" },
            { id: 11, url: "/images/customer/finance-logo-11.png" },
            { id: 12, url: "/images/customer/finance-logo-12.png" },
            { id: 13, url: "/images/customer/finance-logo-13.png" },
            { id: 14, url: "/images/customer/finance-logo-14.png" },
            { id: 15, url: "/images/customer/finance-logo-15.png" },
            { id: 16, url: "/images/customer/finance-logo-16.png" },
            { id: 17, url: "/images/customer/finance-logo-17.png" },
            { id: 18, url: "/images/customer/finance-logo-18.png" },
            { id: 19, url: "/images/customer/finance-logo-19.png" },
            { id: 20, url: "/images/customer/finance-logo-20.png" },
            { id: 21, url: "/images/customer/finance-logo-21.png" },
            { id: 22, url: "/images/customer/finance-logo-22.png" },
            { id: 23, url: "/images/customer/finance-logo-23.png" },
            { id: 24, url: "/images/customer/finance-logo-24.png" },
          ],
        },
        {
          type: "government",
          name: "政府、醫療及企業",
          content: [
            { id: 1, url: "/images/customer/gov-logo-1.png" },
            { id: 2, url: "/images/customer/gov-logo-2.png" },
            { id: 3, url: "/images/customer/gov-logo-3.png" },
            { id: 4, url: "/images/customer/gov-logo-4.png" },
            { id: 5, url: "/images/customer/gov-logo-5.png" },
            { id: 6, url: "/images/customer/gov-logo-6.png" },
            { id: 7, url: "/images/customer/gov-logo-7.png" },
            { id: 8, url: "/images/customer/gov-logo-8.png" },
            { id: 9, url: "/images/customer/gov-logo-9.png" },
            { id: 10, url: "/images/customer/gov-logo-10.png" },
            { id: 11, url: "/images/customer/gov-logo-11.png" },
            { id: 12, url: "/images/customer/gov-logo-12.png" },
            { id: 13, url: "/images/customer/gov-logo-13.png" },
            { id: 14, url: "/images/customer/gov-logo-14.png" },
            { id: 15, url: "/images/customer/gov-logo-15.png" },
            { id: 16, url: "/images/customer/gov-logo-16.png" },
            { id: 17, url: "/images/customer/gov-logo-17.png" },
          ],
        },
      ],
    };
  },
};
</script>
